import { gql, useQuery } from '@apollo/client';
import SkeletonComponent from '@components/common/Skeleton';
import he from 'he';
import { memo, useEffect, useState } from 'react';
import Head from 'next/head';

export const GET_CALL_TO_ACTION = gql`
  query {
    cmsBlocks(identifiers: "call-to-action-banner") {
      items {
        content
        identifier
        title
      }
    }
  }
`;
const CallToActionBanner = () => {
  const { data, loading } = useQuery(GET_CALL_TO_ACTION, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const [imageUrls, setImageUrls] = useState([]);
  const content = data?.cmsBlocks?.items?.[0]?.content;

  useEffect(() => {
    if (content) {
      const imgRegex = /<img[^>]+src="([^">]+)"/g;
      const urls = [];
      let match;
      while ((match = imgRegex.exec(content)) !== null) {
        urls.push(match[1]);
      }
      setImageUrls(urls);
    }
  }, [content]);

  if (loading && !content) {
    return <SkeletonComponent width="100%" height="12.5rem" />;
  }

  if (content) {
    return (
      <>
        <Head>
          {imageUrls?.map((url, index) => (
            <link key={index} rel="preload" as="image" href={url} />
          ))}
        </Head>
        {content && (
          <div
            className="w-full px-4 mx-auto border-box lg:pt-2 lg:mb-2"
            dangerouslySetInnerHTML={{ __html: he.decode(content) }}
          ></div>
        )}
      </>
    );
  }

  return null;
};

const component = memo(CallToActionBanner);

export default component;

component.displayName = 'CallToActionBanner';
