export interface IBanner {
  link: string;
  image?: string;
  mobile_image?: string;
  desktop_image?: string;
  description: string;
}

export interface IInfoBanner {
  icon: string;
  title: string;
  subtitle: string;
}

export interface IHomeBanner {
  data: IBanner[];
  aside: IBanner[];
  isMobile?: boolean;
}

const urlToStr = str => {
  const treatUrl = str.replace(/\/([^(?=.]*)[^]*/gim, '$1');
  const newStr = treatUrl?.replaceAll?.('-', ' ');

  return newStr;
};

export const getHomeBanner = data => {
  const str = data.content.replace(/(\r\n|\n|\n|\r)/gm, '');
  const regex =
    /data-slide-name="(.+?)".+?href="(.+?)"[^.]+desktop_image\\.+?(?=https)(http[^\\]*).+?(?=http)(https[^\\]*)/gi;
  const asideRegex =
    /full-width.+?href="(.+?.+?)".+?src="(http.+?)".+?alt="(.+?)"/gim;

  const asideImage = [...str.matchAll(asideRegex)];
  const arrAside = asideImage.map(i => {
    const prevDescription = urlToStr(i[3]);
    return {
      link: i[1],
      image: i[2],
      description: prevDescription
    };
  });

  const allMatches = [...str.matchAll(regex)];
  const arrData = allMatches.map(i => {
    const prevDescription = urlToStr(i[1]);
    return {
      link: i[2],
      desktop_image: i[3],
      mobile_image: i[4],
      description: prevDescription
    };
  });
  return { data: arrData, aside: arrAside };
};
