import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import { isAfter } from 'date-fns';
import Image from 'next/image';
import Link from 'next/link';

import { CustomImage, Slider } from '@components/common';
import { CountDown } from '@components/common/CountDown';
import ImageFlashSale from '@assets/icons/flash-offer-title.png';
import ImageBackground from '@assets/icons/bg-contador.png';
import ImageBlackFridayBackground from '@assets/images/336x336-flash-offer-black-friday.png';
import { formatCurrency, sufixParse } from '@utils';
import { GetPromotionDocument } from '@generated/graphql';
import { useGTMDataLayer, useStore } from '@hooks';

export interface iPromotionalProduct {
  date_end: string;
  enabled: string;
  final_price: number;
  image: string;
  name: string;
  regular_price: number;
  sku: string;
  tags: {
    image: string;
    name: string;
  }[];
  url: string;
  __typename: string;
}

interface iFlashSaleBannerCard {
  blackFridayTheme?: boolean;
  index: number;
  promotionalProduct: iPromotionalProduct;
  handleExpirePromotionalProduct: () => void;
}

const FlashSaleBannerCard = ({
  blackFridayTheme,
  promotionalProduct,
  index,
  handleExpirePromotionalProduct
}: iFlashSaleBannerCard) => {
  const { pushToDataLayer } = useGTMDataLayer();

  const handlePushToDatalayer = useCallback(() => {
    const promotionalProductsForDatalayer = {
      title: 'FlashOfferSalesBanner',
      ecommerce: {
        currencyCode: 'BRL',
        items: []
      }
    };

    const promotionId = promotionalProduct?.image.match(/\/([^/?#]+)\.jpg$/i);

    if (promotionId?.[1])
      promotionalProductsForDatalayer.ecommerce.items.push({
        promotion_id: promotionId?.[1],
        promotion_name: promotionalProduct?.name,
        creative_name: 'Flash offer',
        location_id: promotionalProduct?.url,
        creative_slot: index + 1
      } as never);

    pushToDataLayer('flashSaleBanner', promotionalProductsForDatalayer, false);
  }, [
    index,
    promotionalProduct?.image,
    promotionalProduct?.name,
    promotionalProduct?.url,
    pushToDataLayer
  ]);

  const promotionalProductRegularPrice = useMemo(() => {
    return formatCurrency(promotionalProduct?.regular_price);
  }, [promotionalProduct?.regular_price]);

  const promotionalProductFinalPrice = useMemo(() => {
    return formatCurrency(promotionalProduct?.final_price);
  }, [promotionalProduct?.final_price]);

  const productSufix = sufixParse('/p');

  return (
    <div
      className={
        !blackFridayTheme
          ? 'relative md:h-[336px] flex justify-center border-box rounded-md'
          : 'relative sm:h-[336px] md:max-h-[360px] lg:max-h-[360px] giflex justify-center border-box rounded-md'
      }
      onClick={handlePushToDatalayer}
    >
      <CustomImage
        src={!blackFridayTheme ? ImageBackground : ImageBlackFridayBackground}
        alt="Imagem de fundo do contador"
        fill
        className="rounded-md z-[1] sm:h-[auto] md:h-[100%] lg:h-[100%]"
        style={{
          objectFit: 'cover',
          height: blackFridayTheme && undefined
        }}
        priority={index === 0}
      />
      <div
        className={
          !blackFridayTheme
            ? 'z-[2] relative p-4 flex flex-col h-full w-full items-center'
            : 'z-[2] relative flex flex-col h-full w-full items-center'
        }
      >
        <div
          className={
            !blackFridayTheme
              ? 'w-full'
              : 'w-full pb-[2.5rem] md:pb-0 pt-[2.5rem]'
          }
        >
          {!blackFridayTheme && (
            <p className="w-full flex items-center justify-center flex-nowrap uppercase text-white font-bold text-sm">
              <Image
                src={ImageFlashSale}
                alt={
                  !blackFridayTheme
                    ? 'Titulo Oferta Relampago'
                    : 'Black Friday Flash Sale'
                }
                className={!blackFridayTheme ? 'w-3/4' : 'w-[100%]'}
                priority={index === 0}
              />
            </p>
          )}
          <CountDown
            endDate={promotionalProduct?.date_end}
            onFinish={handleExpirePromotionalProduct}
            legendFontSize="text-[10px]"
            variant={!blackFridayTheme ? 'light' : 'black-friday-flash-sale'}
          />
          {/* TODO: remove this `replace` solution after backend return the simple product urls */}
          <Link
            href={`/${productSufix(promotionalProduct?.url?.split('/')[3])}`
              .replace('-configurable', '')
              .replace('-config', '')}
          >
            <div
              className={
                !blackFridayTheme
                  ? 'w-full my-4 md:mt-4 md:mb-0 py-[16px] px-[12px] cursor-pointer rounded-md bg-white flex text-primary-medium bn-oferta-relampago'
                  : 'mx-4 my-4 md:mt-4 md:mb-0 py-[16px] cursor-pointer rounded-md bg-white flex text-primary-medium bn-oferta-relampago'
              }
            >
              {promotionalProduct?.image && (
                <div className="w-[120px] h-[120px] relative flex justify-center items-center">
                  <CustomImage
                    src={promotionalProduct?.image}
                    alt={promotionalProduct?.image}
                    width={120}
                    height={120}
                    priority={index === 0}
                  />

                  {promotionalProduct?.tags?.map((tag, i) => (
                    <div
                      className="flex flex-wrap absolute top-0 right-0"
                      key={i}
                    >
                      <span>
                        <CustomImage
                          width={40}
                          height={40}
                          src={tag.image}
                          alt={tag.name}
                          priority={true}
                        />
                      </span>
                    </div>
                  ))}
                </div>
              )}

              <span className="w-full bn-oferta-relampago flex flex-col justify-center ml-4">
                <h1 className="text-base font-bold font-gotham line-clamp-2 bn-oferta-relampago">
                  {promotionalProduct?.name}
                </h1>

                <span className="flex flex-col items-start">
                  <p className="text-sm text-gray-darkest line-through">
                    {promotionalProductRegularPrice}
                  </p>
                  <strong className="text-base font-gotham font-bold">
                    <b className="text-xl font-gotham font-bold">
                      {promotionalProductFinalPrice}
                    </b>
                  </strong>
                </span>
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const FlashSaleBanner = ({ setPromotionalDateIsExpired }) => {
  const [pushedToDatalayer, setPushedToDatalayer] = useState(false);
  const { pushToDataLayer } = useGTMDataLayer();
  const { currentStore } = useStore();

  const [promotionalProducts, setPromotionalProducts] = useState(
    [] as iPromotionalProduct[]
  );

  const { data: dataPromotion } = useQuery(GetPromotionDocument, {
    variables: { sku: '' },
    fetchPolicy: 'cache-and-network'
  });

  const handleExpirePromotionalProduct = useCallback(() => {
    const productsFiltered = promotionalProducts.filter(
      product => !isAfter(new Date(), new Date(product.date_end))
    );

    if (productsFiltered?.length > 0) {
      setPromotionalProducts(productsFiltered);
    } else {
      setPromotionalDateIsExpired(true);
    }
  }, [promotionalProducts, setPromotionalDateIsExpired]);

  useEffect(() => {
    const promotionalProduct =
      dataPromotion?.promotionBf as iPromotionalProduct[];

    if (!isEmpty(promotionalProduct)) {
      const productsFiltered = promotionalProduct.filter(
        product => !isAfter(new Date(), new Date(product.date_end))
      );

      setPromotionalProducts(productsFiltered);

      const promotionalProductsForDatalayer = {
        title: 'FlashOfferSalesBanner',
        ecommerce: {
          currencyCode: 'BRL',
          items: []
        }
      };

      productsFiltered.map((product, index) => {
        const promotionId = product?.image.match(/\/([^/?#]+)\.(jpg|png)$/i);

        if (promotionId?.[1])
          promotionalProductsForDatalayer.ecommerce.items.push({
            promotion_id: promotionId?.[1],
            promotion_name: product?.name,
            creative_name: 'Flash offer',
            creative_slot: index + 1,
            location_id: product?.url
          } as never);
      });

      if (!pushedToDatalayer) {
        pushToDataLayer(
          'flashSaleBannerPageView',
          promotionalProductsForDatalayer
        );
        setPushedToDatalayer(true);
      }
    }
  }, [dataPromotion?.promotionBf, pushToDataLayer, pushedToDatalayer]);

  return (
    <>
      {promotionalProducts?.length > 0 && (
        <Slider
          infinite={promotionalProducts?.length > 1 ? true : false}
          showDots={promotionalProducts?.length > 1 ? true : false}
          autoPlay
          autoPlaySpeed={3000}
          arrows={false}
          activeDotsClass={
            !currentStore?.skin_black_friday
              ? 'bg-tertiary-darkest'
              : 'bg-[#FBFBFE] hover:bg-white-dark'
          }
          inactiveDotsClass="bg-secondary-lightest"
        >
          {promotionalProducts?.map((product, index) => (
            <FlashSaleBannerCard
              key={product.sku}
              index={index}
              promotionalProduct={product}
              handleExpirePromotionalProduct={handleExpirePromotionalProduct}
              blackFridayTheme={currentStore?.skin_black_friday}
            />
          ))}
        </Slider>
      )}
    </>
  );
};

export default FlashSaleBanner;
