import TimerIcon from '@assets/icons/timer.svg';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styles from './countdown.module.scss';

export function CountDown({
  endDate = '2022-11-25T00:00:00',
  showLegend = true,
  showDays = false,
  variant = 'light',
  fontSize = 'text-xl',
  legendFontSize = 'text-md',
  onFinish = () => {}
}) {
  const [time, setTime] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });

  useEffect(() => {
    const addZero = str => str.padStart(2, '0');

    const preventNegative = value => Math.max(value, 0);

    const prepareNumber = value => addZero(preventNegative(value).toString());

    const dateStr = endDate?.replace(' ', 'T');
    const countDownDate = new Date(dateStr).getTime();
    const countDownClock = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        showDays
          ? (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          : distance / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // If the count down is over, write some text
      if (distance < 0) {
        onFinish();
        clearInterval(countDownClock);
      }

      setTime({
        days: prepareNumber(days),
        hours: prepareNumber(hours),
        minutes: prepareNumber(minutes),
        seconds: prepareNumber(seconds)
      });
    }, 250);

    return () => clearInterval(countDownClock);
  }, [endDate, onFinish, showDays]);

  const timeLabels = (key: string) => {
    const labels = {
      days: 'DIAS',
      hours: 'HORAS',
      minutes: 'MIN',
      seconds: 'SEG'
    };
    return labels[key] || '';
  };

  return (
    <div
      className={classNames(
        { 'bg-primary-darkest': variant === 'dark' },
        'flex flex-col justify-center items-center'
      )}
    >
      <div className="flex flex-col">
        {variant === 'black-friday' && (
          <p className="font-bold lg:text-[18px] text-center flex items-center gap-2 self-center font-gotham">
            A BLACK FRIDAY ESTÁ CHEGANDO{' '}
            <TimerIcon className={styles.timerIcon} />
          </p>
        )}
        <div
          className={classNames(
            'flex justify-center gap-1 md:justify-between',
            variant === 'black-friday-flash-sale'
              ? 'mt-6 items-start'
              : 'items-start'
          )}
        >
          {Object.entries(time).map(([key, value]) =>
            // if showDays is false omit the days
            !showDays && key === 'days' ? null : (
              <>
                <div
                  className={classNames('border-0 rounded-md', {
                    'bg-gray-lightest': variant === 'light',
                    'bg-primary-medium': variant === 'dark',
                    'bg-transparent': variant === 'black-friday'
                  })}
                >
                  <div
                    className={classNames(
                      `flex align-center mr-1`,
                      variant === 'black-friday-flash-sale' &&
                        `justify-end mx-1`
                    )}
                  >
                    {value.split('').map(x => {
                      return (
                        <>
                          <div
                            className={classNames(
                              `text-center border-0 rounded-md`,
                              variant === 'black-friday-flash-sale' &&
                                `${styles.clockDigit} text-[3rem]`,
                              variant === 'black-friday' &&
                                `${fontSize} ${styles.clockDigit}`,
                              {
                                'w-[30px] m-1 mr-0 p-1 font-bold bg-white-lightest text-primary-dark':
                                  variant === 'light',
                                'w-[30px] m-1 mr-0 p-1 font-bold bg-primary-dark text-white':
                                  variant === 'dark',
                                'w-[28px] lg:w-[45px] lg:my-4 text-[3rem] leading-none':
                                  variant === 'black-friday'
                              }
                            )}
                          >
                            {x}
                          </div>
                        </>
                      );
                    })}
                  </div>
                  {showLegend && (
                    <div
                      className={classNames(
                        `font-bold text-center ${legendFontSize}`,
                        {
                          'text-primary-dark': variant === 'light',
                          'text-white': ['dark', 'black-friday'].includes(
                            variant
                          )
                        },
                        variant === 'black-friday-flash-sale' &&
                          `text-white text-[14px] ${legendFontSize}`
                      )}
                    >
                      {timeLabels(key)}
                    </div>
                  )}
                </div>
                <div
                  className={classNames(
                    'last-of-type:hidden',
                    variant === 'black-friday'
                      ? styles.clockColon
                      : variant == 'black-friday-flash-sale'
                        ? styles.clockColonFlashSale
                        : ' text-white font-bold text-[20px]',
                    {
                      'lg:text-[80px] mt-[3%] lg:mt-[7.5%]':
                        variant === 'black-friday'
                    }
                  )}
                >
                  :
                </div>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
}
